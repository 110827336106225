import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { currency_symbol, fontName } from "../../../constants/utils";
import MenuClosed from "../MenuClosed/MenuClosed";
import {
  saveCartTotals,
  saveMinOrderAmountError,
} from "../../../store/actions/cart";
import Loader from "react-loader-spinner";

const useStyles = makeStyles(CartStyle);

export default function CartTotals(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const {
    ui_strings,
    display_currency,
    price_decimal_places,
    currency,
    currency_symbol: cSymbol,
    theme_data,
    minimum_order_amount,
    taxes,
    cart_subtotal_include_tax,
  } = menuInfo.menuData;
  const {
    deliveryFees,
    products,
    discount,
    orderType,
    minOrderAmountError,
    coupon,
    iikoLoyaltyDiscounts,
    loadingCoupon,
    loadingEmployeeDiscount,
    loadingIikoLoyaltyDiscounts,
  } = cartInfo;

  const { min_order_value, free_delivery } = coupon;

  const subTotalTextStyle = {
    color: theme_data?.cart_screen_cart_subtotal_text_color,
    ...fontName(theme_data?.cart_screen_cart_subtotal_font_family),
    float: ["ar", "he"].includes(menuInfo.selected_language) ? "left" : "right",
  };

  // Total cart items is number of products times the qty
  const totalCartItems = products.reduce(
    (prev, product) => prev + product.qty,
    0
  );

  const cartSubTotalStr = ui_strings?.ui_str_cart_subtotal
    ? ui_strings.ui_str_cart_subtotal
        .replace(/items/g, totalCartItems > 1 ? "items" : "item")
        .replace(/%s/g, totalCartItems)
    : `Subtotal (${totalCartItems} items)`;

  const totalAmountStyle = {
    color: theme_data?.cart_screen_cart_total_amount_text_color,
    ...fontName(theme_data?.cart_screen_cart_subtotal_font_family),
    fontWeight: "bold",
    float: ["ar", "he"].includes(menuInfo.selected_language) ? "left" : "right",
  };

  const cartSubTotal =
    products &&
    products
      .reduce((prev, product) => prev + product.price * product.qty, 0)
      .toFixed(3);

  const [cartSubTotalWithoutTax, setCartSubTotalWithoutTax] = useState(
    cartSubTotal
  );

  const [cartTotal, setCartTotal] = useState(cartSubTotal);

  const [freeDelivery, setFreeDelivery] = useState(
    free_delivery && parseFloat(min_order_value) <= cartSubTotal
  );

  const getTaxAmount = useCallback(
    (rate) => {
      let subtotal = cartSubTotal;
      // If iikoLoyaltyDiscounts available
      if (iikoLoyaltyDiscounts.length > 0) {
        iikoLoyaltyDiscounts.map((loyalty) => {
          if (loyalty.discounts.length > 0) {
            let totalDiscounts = 0;
            loyalty.discounts.map((discount) => {
              totalDiscounts += discount.discountSum;
              return null;
            });
            subtotal = (
              parseFloat(subtotal) - parseFloat(totalDiscounts)
            ).toFixed(3);
          }
          return null;
        });
      }

      return (
        Math.round(
          ((subtotal * parseFloat(rate)) / (100 + parseFloat(rate))) *
            (price_decimal_places === 3 ? 1000 : 100)
        ) / (price_decimal_places === 3 ? 1000 : 100)
      ).toFixed(price_decimal_places);
    },
    [cartSubTotal, price_decimal_places, iikoLoyaltyDiscounts]
  );

  const getInclusiveTaxAmount = useCallback(
    (rate, totalTaxRate) => {
      let subtotal = cartSubTotal;
      // If iikoLoyaltyDiscounts available
      if (iikoLoyaltyDiscounts.length > 0) {
        iikoLoyaltyDiscounts.map((loyalty) => {
          if (loyalty.discounts.length > 0) {
            let totalDiscounts = 0;
            loyalty.discounts.map((discount) => {
              totalDiscounts += discount.discountSum;
              return null;
            });
            subtotal = (
              parseFloat(subtotal) - parseFloat(totalDiscounts)
            ).toFixed(3);
          }
          return null;
        });
      }

      return (
        Math.round(
          ((subtotal * (parseFloat(rate) / 100)) /
            (1 + parseFloat(totalTaxRate) / 100)) *
            (price_decimal_places === 3 ? 1000 : 100)
        ) / (price_decimal_places === 3 ? 1000 : 100)
      ).toFixed(price_decimal_places);
    },
    [cartSubTotal, price_decimal_places, iikoLoyaltyDiscounts]
  );

  const cartTaxesTotalRate = taxes?.reduce((prev, tax) => {
    if (tax.type === "percentage") {
      return prev + parseFloat(tax.rate);
    } else {
      return prev;
    }
  }, 0);

  const cartTaxes =
    taxes.length > 0
      ? taxes.map((tax) => ({
          name: tax.name,
          amount:
            tax.type === "percentage" && cart_subtotal_include_tax
              ? getInclusiveTaxAmount(tax.rate, cartTaxesTotalRate) || 0 // If cart_subtotal_include_tax is true, then get inclusive tax amount
              : tax.type === "percentage" && !cart_subtotal_include_tax
              ? getTaxAmount(tax.rate) // If cart_subtotal_include_tax is false, then get exclusive tax amount
              : parseFloat(tax.rate).toFixed(price_decimal_places),
        }))
      : [];

  const [loadingTotals, setLoadingTotals] = useState(false);

  useEffect(() => {
    if (
      loadingCoupon ||
      loadingEmployeeDiscount ||
      loadingIikoLoyaltyDiscounts
    ) {
      setLoadingTotals(true);
    } else {
      setLoadingTotals(false);
    }
  }, [loadingCoupon, loadingEmployeeDiscount, loadingIikoLoyaltyDiscounts]);

  useEffect(() => {
    setFreeDelivery(
      free_delivery && parseFloat(min_order_value) <= cartSubTotal
    );
  }, [cartSubTotal, free_delivery, min_order_value]);

  useEffect(() => {
    let taxRates = 0;
    if (taxes.length > 0) {
      for (let tax of taxes) {
        if (tax.type === "percentage") {
          taxRates = taxRates + parseFloat(tax.rate);
        }
      }
    }

    const taxAmount = getTaxAmount(taxRates) || 0;

    const cartSubTotalWithoutTax = (
      Math.round((cartSubTotal - taxAmount) * 100) / 100
    ).toFixed(price_decimal_places);
    setCartSubTotalWithoutTax(cartSubTotalWithoutTax);
  }, [cartSubTotal, getTaxAmount, price_decimal_places, taxes]);

  useEffect(() => {
    let fixedTaxAmount = 0;
    if (taxes.length > 0) {
      for (let tax of taxes) {
        if (tax.type === "fixed") {
          fixedTaxAmount += parseFloat(tax.rate);
        }
      }
    }

    let totalAmount = 0;

    if (orderType === "delivery" && deliveryFees && deliveryFees > 0) {
      if (freeDelivery) {
        totalAmount = (
          parseFloat(cartSubTotal) +
          parseFloat(fixedTaxAmount) -
          parseFloat(discount)
        ).toFixed(3);
      } else {
        totalAmount = (
          parseFloat(cartSubTotal) +
          parseFloat(fixedTaxAmount) +
          parseFloat(deliveryFees) -
          parseFloat(discount)
        ).toFixed(3);
      }
    } else {
      totalAmount = (
        parseFloat(cartSubTotal) +
        parseFloat(fixedTaxAmount) +
        -parseFloat(discount)
      ).toFixed(3);
    }

    // If iikoLoyaltyDiscounts available
    if (iikoLoyaltyDiscounts.length > 0) {
      iikoLoyaltyDiscounts.map((loyalty) => {
        if (loyalty.discounts.length > 0) {
          let totalDiscounts = 0;
          loyalty.discounts.map((discount) => {
            totalDiscounts += discount.discountSum;
            return null;
          });
          totalAmount = (
            parseFloat(totalAmount) - parseFloat(totalDiscounts)
          ).toFixed(3);
        }
        return null;
      });
    }

    setCartTotal(totalAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartSubTotal,
    deliveryFees,
    discount,
    orderType,
    taxes,
    freeDelivery,
    iikoLoyaltyDiscounts,
  ]);

  useEffect(() => {
    if (cartSubTotal && cartTotal) {
      dispatch(
        saveCartTotals(
          cartTaxes,
          cart_subtotal_include_tax
            ? parseFloat(cartSubTotal).toFixed(price_decimal_places)
            : parseFloat(cartSubTotalWithoutTax).toFixed(price_decimal_places),
          parseFloat(cartTotal).toFixed(price_decimal_places)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartSubTotal, cartSubTotalWithoutTax, cartTotal]);

  useEffect(() => {
    if (
      orderType === "delivery" &&
      minimum_order_amount &&
      parseFloat(cartSubTotal) < parseFloat(minimum_order_amount) &&
      products.length > 0
    ) {
      dispatch(saveMinOrderAmountError(true));
    } else {
      dispatch(saveMinOrderAmountError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, cartSubTotal, orderType, minimum_order_amount]);

  return (
    <div className={classes.menuTotalsContainer}>
      {loadingTotals && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader
            type="Oval"
            height={30}
            width={30}
            color={theme_data?.cart_screen_cart_total_amount_text_color}
          />
        </div>
      )}
      <div
        className={classes.menuTotals}
        style={{
          filter: loadingTotals ? "blur(3px)" : "none",
        }}
      >
        {minOrderAmountError && (
          <MenuClosed
            margins={true}
            text={`${
              ui_strings?.ui_str_minimum_order_amount
                ? ui_strings.ui_str_minimum_order_amount
                : "Minimum order amount required"
            }: ${
              display_currency
                ? cSymbol
                  ? cSymbol
                  : currency_symbol(currency)
                : null
            } ${parseFloat(minimum_order_amount).toFixed(
              price_decimal_places
            )}`}
          />
        )}

        <div className={classes.menuTotalsItem} style={subTotalTextStyle}>
          {cartSubTotalStr}:{" "}
          <span style={subTotalTextStyle}>
            {display_currency
              ? cSymbol
                ? cSymbol
                : currency_symbol(currency)
              : null}
            {parseFloat(
              cart_subtotal_include_tax ? cartSubTotal : cartSubTotalWithoutTax
            ).toFixed(price_decimal_places)}
          </span>
        </div>

        {iikoLoyaltyDiscounts.length > 0 &&
          iikoLoyaltyDiscounts
            .filter((loyalty) => loyalty.discounts.length > 0)
            .map((loyalty, index) => {
              let totalDiscounts = 0;
              loyalty.discounts.map((discount) => {
                totalDiscounts += discount.discountSum;
                return null;
              });
              return (
                <div
                  className={classes.menuTotalsItem}
                  style={subTotalTextStyle}
                  key={index}
                >
                  {loyalty.name}
                  {":"}
                  <span style={subTotalTextStyle}>
                    -{" "}
                    {display_currency
                      ? cSymbol
                        ? cSymbol
                        : currency_symbol(currency)
                      : null}
                    {parseFloat(totalDiscounts).toFixed(price_decimal_places)}
                  </span>
                </div>
              );
            })}

        {cartTaxes.map((tax, index) => (
          <div
            className={classes.menuTotalsItem}
            style={subTotalTextStyle}
            key={index}
          >
            {tax.name}
            {":"}
            <span style={subTotalTextStyle}>
              {display_currency
                ? cSymbol
                  ? cSymbol
                  : currency_symbol(currency)
                : null}
              {parseFloat(tax.amount).toFixed(price_decimal_places)}
            </span>
          </div>
        ))}

        {orderType === "delivery" && deliveryFees > 0 && (
          <div className={classes.menuTotalsItem} style={subTotalTextStyle}>
            {ui_strings?.ui_str_delivery_fees
              ? ui_strings.ui_str_delivery_fees
              : "Delivery Fees"}
            {":"}
            <span
              style={{
                textDecoration: freeDelivery ? "line-through" : "none",
                ...subTotalTextStyle,
              }}
            >
              {display_currency
                ? cSymbol
                  ? cSymbol
                  : currency_symbol(currency)
                : null}
              {parseFloat(deliveryFees).toFixed(price_decimal_places)}
            </span>
          </div>
        )}

        {discount > 0 && (
          <div className={classes.menuTotalsItem} style={subTotalTextStyle}>
            {ui_strings?.ui_str_discount
              ? ui_strings.ui_str_discount
              : "Discount"}
            {":"}
            <span style={subTotalTextStyle}>
              -{" "}
              {display_currency
                ? cSymbol
                  ? cSymbol
                  : currency_symbol(currency)
                : null}
              {parseFloat(discount).toFixed(price_decimal_places)}
            </span>
          </div>
        )}

        <div className={classes.menuTotalsItem} style={totalAmountStyle}>
          {ui_strings?.ui_str_cart_total
            ? ui_strings.ui_str_cart_total
            : "Total"}
          :{" "}
          <span style={totalAmountStyle}>
            {display_currency
              ? cSymbol
                ? cSymbol
                : currency_symbol(currency)
              : null}
            {parseFloat(cartTotal).toFixed(price_decimal_places)}
          </span>
        </div>
      </div>

      <div className={classes.menuTotalsWave}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343 13.98">
          <path
            fill="#fff"
            d="M0 0v8.114a4 4 0 0 0 5.691 3.625l11.674-5.445a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l15.673 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l15.673 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.381 0l15.675 7.311a4 4 0 0 0 3.381 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l11.673 5.445A4 4 0 0 0 343 8.114V0Z"
            data-name="Path 54"
          />
        </svg>
      </div>
    </div>
  );
}
