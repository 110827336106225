import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { css } from "@emotion/css";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";
import ReactPlayer from "react-player/lazy";

const useStyles = makeStyles(CategoryStyle);
// install Swiper components
SwiperCore.use([Navigation, Pagination]);

export default function RenderPromotions(props) {
  const { currentCategory } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { promotions, theme_data } = menuInfo.menuData;
  const [activeIndex, setActiveIndex] = useState(0);
  const videoRefs = useRef([]);

  const onSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);

    // Pause all videos that are not in the active slide
    videoRefs.current.forEach((player, index) => {
      if (player && index !== swiper.activeIndex) {
        player.getInternalPlayer().pause();
      }
    });
  };

  return (
    <div className={classes.promotions}>
      <Swiper
        simulateTouch={false}
        slidesPerView={1}
        navigation={currentCategory.promotions.length > 1}
        pagination={{ clickable: true }}
        onInit={onSlideChange}
        onSlideChange={onSlideChange}
        className={css`
          > .swiper-button-prev,
          > .swiper-button-next {
            color: ${theme_data?.product_screen_arrow_color};
          }
        `}
      >
        {currentCategory.promotions.map((promo, index) => {
          const promotion = promotions.find(
            (promotion) => promotion.id === promo
          );
          return (
            <SwiperSlide key={index}>
              {promotion.video ? (
                <ReactPlayer
                  ref={(el) => (videoRefs.current[index] = el)}
                  url={promotion.video}
                  playing={index === activeIndex}
                  loop
                  playsinline
                  muted={false}
                  controls={true}
                  width={"100%"}
                  height={"100%"}
                  config={{
                    file: {
                      attributes: {
                        preload: "auto",
                        disablePictureInPicture: true,
                        controlsList: "noplaybackrate nodownload",
                        poster: promotion.image.product,
                      },
                    },
                  }}
                />
              ) : (
                <img
                  src={promotion.image.product}
                  srcSet={`${promotion.image.product} 1x,
                ${promotion.image.retina} 2x`}
                  alt={promotion.title}
                  className={classes.promoImage}
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
